export const ADD_NOTE = "ADD_NOTE";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_NOTES = "CLEAR_NOTES";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAILED = "CREATE_NOTE_FAILED";
export const DELETE_NOTE = "DELETE_NOTE";
export const EDIT_NOTE = "EDIT_NOTE";
export const GET_ERRORS = "GET_ERRORS";
export const GET_NOTE_FAIL = "GET_NOTE_FAIL";
export const GET_NOTES = "GET_NOTES";
export const GET_USER = "GET_USER";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOAD_NOTE = "LOAD_NOTE";
export const LOAD_NOTE_FAILED = "LOAD_NOTE_FAILED";
export const LOAD_NOTE_SUCCESS = "LOAD_NOTE_SUCCESS";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILED = "UPDATE_NOTE_FAILED";
export const NOTE_LOADING = "NOTE_LOADING";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET_FAILED";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
